// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA28aqTIoDjsjISwehCWIgFPcoa2L-EXgo',
  authDomain: 'my-portfolio-nthungdev.firebaseapp.com',
  databaseURL: 'https://my-portfolio-nthungdev.firebaseio.com',
  projectId: 'my-portfolio-nthungdev',
  storageBucket: 'my-portfolio-nthungdev.appspot.com',
  messagingSenderId: '995220339944',
  appId: '1:995220339944:web:0fba38fb906de077a01d5d',
  measurementId: 'G-XZVLJ6B601',
}

export { firebaseConfig }
